<div class="flex flex-col justify-center px-2 pt-2">
  <pd-overlay-loader class="pt-8" [loading]="pageState.isSaving"></pd-overlay-loader>
  <div *ngIf="showHeader" class="mb-4 flex min-w-full flex-row justify-between px-2 font-bold">
    <span>Welcome {{ userName }}</span>
    <button mat-icon-button matSuffix type="button" class="ml-auto text-primary dark:text-primary-light" (click)="logout()">
      <pd-icon [icon]="'sign-out'"></pd-icon>
    </button>
  </div>
  <div class="mb-4 px-2">
    <p>In order to provide Instant Payments; we connect via Debit Card.</p>
    <p>Please enter a valid Debit Card to receive payments directly to your card account.</p>
  </div>

  <div #tabapayIframe></div>
  <div class="flex flex-col px-2">
    <p *ngIf="invalidCardError" class="py-5 text-center text-red-500">Debit Card Invalid; please attempt a different card</p>
    <p class="mt-4 inline-block text-nowrap text-end text-2xs">
      POWERED BY<pd-paidiem-logo [showSlogan]="false" class="ml-2 inline-block max-w-24 align-middle"></pd-paidiem-logo>
    </p>
    <div class="flex flex-row justify-end">
      <img [width]="15" alt="United States" src="assets/flag_USA.svg" />
    </div>
    <p class="mt-4 inline-block text-xs">
      By providing and selecting Use Card to submit debit-card details you agree to our
      <pd-policies-modal
        linkStyleClasses="text-xs text-primary dark:text-primary-light"
        [focusClass]="'.card_collection'"></pd-policies-modal>
    </p>
    <p class="mt-4 inline-block text-xs">
      For Customer Service contact <a class="text-xs underline" [href]="'mailto:support@paidiem.com'">support&#64;paidiem.com</a>
    </p>
  </div>
</div>
